
import { Component, Mixins } from "vue-property-decorator";
import LabelHelpers from "@/mixins/LabelHelpers";
import jsPDF from "jspdf";

@Component
export default class EndRequest extends Mixins(LabelHelpers) {
  motivos = [];
  in_throttle = false;
  logo_av = "";
  logo_client = "";
  protected downloading_file = false;
  protected download_progress = 0;

  protected updateProgressFromChild(value: number) {
    this.download_progress = value;
  }

  protected get progressLabel() {
    return Math.round(this.download_progress * 100).toString() + "%";
  }

  private humanDate(date: any) {
    return (
      date.split("")[8] +
      date.split("")[9] +
      "-" +
      date.split("")[5] +
      date.split("")[6] +
      "-" +
      date.split("")[0] +
      date.split("")[1] +
      date.split("")[2] +
      date.split("")[3]
    );
  }

  protected humanTime(date: any) {
    let date_object = new Date(date);
    let date_time = date_object.toLocaleString("es-CL", { timeZone: "UTC" });
    return date_time.split(" ")[1];
  }

  private mounted() {
    this.$axios
      .get("/motivos/listar", {
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        if ("" == response.data) {
          this.motivos = [];
        } else {
          this.motivos = response.data;
        }
        this.$q.loading.hide();
      })
      .catch(error => {
        this.$q.loading.hide();
      });

    this.$gtag.event(
      "Solicitud Creada: " + this.$store.state.ticket.service.name,
      {
        event_label: "Solicitud Creada",
        event_category: "test",
        value: 1
      }
    );
    window.scroll(0, 0);
  }

  private throttlePrint() {
    if (!this.in_throttle) {
      this.printTicket();
      this.in_throttle = true;
      setTimeout(() => (this.in_throttle = false), 5000);
    }
  }

  private printTicket() {
    this.logo_av = this.imageToBase64(
      process.env.VUE_APP_AWS_ASSETS + "/logos/logo-uno.png"
    );
    const filename = "MiSolicitud.pdf";
    let doc = new jsPDF("p", "mm", "letter");
    //Header
    doc.addImage(this.logo_av, "PNG", 150, 5, 52, 19);
    //doc.addImage(this.logo_client, "PNG", 20, 7, 61, 20);
    doc.setFontSize(20);
    doc.text(
      "Confirmación de Recepción de la Solicitud",
      doc.internal.pageSize.getWidth() / 2,
      40,
      null,
      null,
      "center"
    );

    //Section 1
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(30, 50, 156, 30, 3, 3, "FD");
    doc.setFontSize(16);
    doc.setTextColor(0);
    doc.text("Nº de Ticket:", 35, 60);
    doc.setFontSize(18);
    doc.setFontType("bold");
    doc.text(
      this.$store.state.ticket.id.toString(),
      (doc.internal.pageSize.getWidth() - 40) / 2,
      60
    );
    doc.setFontSize(16);
    doc.setFontType("normal");
    doc.text("Fecha y Hora:", 35, 75);
    doc.setFontType("bold");
    doc.text(
      this.humanDate(this.$store.state.ticket.date),
      (doc.internal.pageSize.getWidth() - 40) / 2,
      75
    );
    //Section 2
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(30, 90, 156, 45, 3, 3, "FD");
    doc.setFontSize(12);
    doc.setFontType("bold");
    doc.text("Datos Asociados", 32, 88);
    doc.setFontSize(14);
    doc.setFontType("normal");
    doc.text("Nombre Solicitante:", 35, 100);
    doc.setFontType("bold");
    doc.text(this.fullName, (doc.internal.pageSize.getWidth() - 40) / 2, 100);
    doc.setFontType("normal");
    doc.text("Servicio Seleccionado:", 35, 110);
    let array_service = doc.splitTextToSize(
      this.$store.state.ticket.service.name,
      100
    );
    doc.setFontType("bold");
    if (array_service.length == 1) {
      doc.setFontSize(14);
    } else {
      doc.setFontSize(12);
    }
    doc.text(array_service, (doc.internal.pageSize.getWidth() - 40) / 2, 110);

    doc.setFontSize(14);
    doc.setFontType("normal");
    doc.text("Motivo del Contacto:", 35, 130);
    doc.setFontType("bold");
    doc.text(this.motive, (doc.internal.pageSize.getWidth() - 40) / 2, 130);

    let file_number = this.$store.state.ticket.files
      ? this.$store.state.ticket.files.length.toString()
      : "0";
    doc.setFontSize(14);
    doc.setFontType("normal");
    doc.text("Cantidad de Documentos Adjuntos:", 32, 143);
    doc.setFontType("bold");
    doc.text(file_number, 120, 143);

    doc.text("Mensaje", 32, 153);
    //ticket

    let array_message = doc.splitTextToSize(
      this.$store.state.ticket.message,
      170
    );
    doc.setFontType("normal");
    if (this.$store.state.ticket.message) {
      let message = array_message.slice(0, 20);
      if (array_message.length >= 20) {
        message.push(
          "Se omitieron " + (array_message.length - 20) + " lineas adicionales."
        );
      }
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(30, 155, 156, 1 + message.length * 5, 3, 3, "FD");
      doc.setFontSize(12);
      doc.setTextColor(0);
      doc.text(message, 40, 160);
    }

    doc.save(filename);
  }

  private imageToBase64(src: any) {
    let image = new Image();
    image.src = src;
    let canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    let ctx = canvas.getContext("2d");
    ctx!.drawImage(image, 0, 0);
    let dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  private download(id: number, nombre: string) {
    if (this.downloading_file) {
      this.$q.notify({
        message: "Solo puede descargar un archivo a la vez",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return 0;
    }
    this.downloading_file = true;
    this.$q.notify({
      message: this.$t("notification.download_start") as string,
      color: "principal",
      position: "top",
      timeout: 5000
    });

    this.$axios
      .get("ticket-adjuntos/bajar/" + id.toString(), {
        params: {
          tipo_documento: this.$store.state.logged
            ? Number(this.$store.state.user.document_type)
            : Number(this.$store.state.ticket.guest_document_type),
          rut: this.$store.state.logged
            ? this.$store.state.user.rut
            : this.$store.state.ticket.guest_id
        },
        responseType: "blob",
        onDownloadProgress: progressEvent => {
          this.download_progress =
            Math.round((progressEvent.loaded * 100) / progressEvent.total) /
            100;
        }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nombre); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloading_file = false;
        this.download_progress = 0;
      })
      .catch(error => {
        this.$q.notify({
          message: this.$t("notification.download_error") as string,
          color: "principal",
          position: "top",
          timeout: 5000
        });
        this.downloading_file = false;
        this.download_progress = 0;
      });
  }

  private get fullName() {
    if (this.$store.state.logged) {
      return (
        this.$store.state.user.name +
        " " +
        this.$store.state.user.father_last_name +
        " " +
        this.$store.state.user.mother_last_name
      );
    } else {
      return (
        this.$store.state.ticket.guest_name +
        " " +
        this.$store.state.ticket.guest_father_name +
        " " +
        this.$store.state.ticket.guest_mother_name
      );
    }
  }

  private routerGo(name: any) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          this.$q.notify({
            message: "Ha ocurrido un error",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
      }
    }
  }

  private get motive() {
    switch (this.$store.state.ticket.motive.toString()) {
      case "0":
        return "Solicitud";
      case "1":
        return "Reclamo";
      case "2":
        return "Sugerencia";
    }
    return "Error";
  }

  /*private getBtnColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = "yellow";
        break;
      case 2:
        color = "orange";
        break;
      case 3:
        color = "purple";
        break;
      case 4:
        color = "cyan";
        break;
      case 5:
        color = "blue";
        break;
      case 8:
        color = "lightgreen";
        break;
      case 6:
        color = "green";
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private getStateColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = "#f9da5e";
        break;
      case 2:
        color = "orange";
        break;
      case 3:
        color = "purple";
        break;
      case 4:
        color = "cyan";
        break;
      case 5:
        color = "blue";
        break;
      case 6:
        color = "green";
        break;
      case 9:
        color = "grey";
        break;
      case 10:
        color = "purple";
        break;
      default:
        color = "";
        break;
    }
    return color;
  }*/
}

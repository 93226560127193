import { Component, Vue } from "vue-property-decorator";
// @ts-ignore-start
import colores from "@/scss/colores.scss";
// @ts-ignore-end
declare module "vue/types/vue";

@Component({
  components: {
    colores
  }
})
export default class LabelHelpers extends Vue {
  protected getBtnColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_cuatro;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 7:
        color = colores.color_estado_siete;
        break;
      case 8:
        color = colores.color_estado_ocho;
        break;
      case 9:
        color = colores.color_estado_nueve;
        break;
      case 10:
        color = colores.color_estado_diez;
        break;
      case 11:
        color = colores.color_estado_once;
        break;
      case 12:
        color = colores.color_estado_doce;
        break;
      case 13:
        color = colores.color_estado_trece;
        break;
      case 14:
        color = colores.color_estado_catorce;
        break;
      case 15:
        color = colores.color_estado_quince;
        break;
      case 16:
        color = colores.color_estado_dieciseis;
        break;
      case 17:
        color = colores.color_estado_diecisiete;
        break;
      case 18:
        color = colores.color_estado_dieciocho;
        break;
      case 19:
        color = colores.color_estado_diecinueve;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  protected getExpiredColor(expired: number) {
    let color = "";
    switch (expired) {
      case 0:
        color = colores.color_estado_seis;
        break;
      case 1:
        color = colores.color_estado_nueve;
        break;
      case 2:
        color = colores.color_estado_uno;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  protected getStatusName(state: number) {
    let status_name = "Desconocido";
    switch (state) {
      case 1:
        status_name = "Gestión";
        break;
      case 2:
        status_name = "Revisión";
        break;
      case 3:
        status_name = "Derivado";
        break;
      case 4:
        status_name = "Para VB Jefe";
        break;
      case 5:
        status_name = "VB Director";
        break;
      case 6:
        status_name = "Cerrado";
        break;
      case 8:
        status_name = "Con VB Jefe";
        break;
      case 9:
        status_name = "Despachado";
        break;
      case 10:
        status_name = "Novedad";
        break;
      case 11:
        status_name = "Reasignado";
        break;
      case 12:
        status_name = "Novedad C";
        break;
      default:
        status_name = "Desconocido";
        break;
    }
    return status_name;
  }

  protected getOriginName(origin: number) {
    let status_name = "Desconocido";
    switch (origin) {
      case 1:
        status_name = "Atención Vecino";
        break;
      case 4:
        status_name = "Web";
        break;
      case 7:
        status_name = "App";
        break;
      case 8:
        status_name = "Correo";
        break;
      case 9:
        status_name = "Carta";
        break;
      case 10:
        status_name = "Presencial";
        break;
      case 11:
        status_name = "Audiencia";
        break;
      case 12:
        status_name = "RRSS";
        break;
      default:
        status_name = "Desconocido";
        break;
    }
    return status_name;
  }

  protected getPaginationLabel(
    firstRowIndex: number,
    endRowIndex: number,
    totalRowsNumber: number
  ) {
    return firstRowIndex + " - " + endRowIndex + " de " + totalRowsNumber;
  }

  protected getMotive(type: number) {
    let motive = "";
    switch (type) {
      case 0:
        motive = "Solicitud";
        break;
      case 1:
        motive = "Reclamo";
        break;
      case 2:
        motive = "Sugerencia";
        break;
      case 3:
        motive = "Contacto";
        break;
      case 4:
        motive = "Reserva de Hora";
        break;
      default:
        motive = "No encontrado";
        break;
    }
    return motive;
  }

  protected getColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_cuatro;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 7:
        color = colores.color_estado_siete;
        break;
      case 8:
        color = colores.color_estado_ocho;
        break;
      case 9:
        color = colores.color_estado_nueve;
        break;
      case 10:
        color = colores.color_estado_diez;
        break;
      case 11:
        color = colores.color_estado_once;
        break;
      case 12:
        color = colores.color_estado_doce;
        break;
      case 13:
        color = colores.color_estado_trece;
        break;
      case 14:
        color = colores.color_estado_catorce;
        break;
      case 15:
        color = colores.color_estado_quince;
        break;
      case 16:
        color = colores.color_estado_dieciseis;
        break;
      case 17:
        color = colores.color_estado_diecisiete;
        break;
      case 18:
        color = colores.color_estado_dieciocho;
        break;
      case 19:
        color = colores.color_estado_diecinueve;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  protected getStateLabel(code: number) {
    switch (code) {
      case 1:
        return "Activo";
      case 0:
        return "Inactivo";
      default:
        return "Error";
    }
  }

  protected getStateDayLabel(code: number) {
    switch (code) {
      case 1:
        return "Presente";
      case 0:
        return "Ausente";
      case 2:
        return "Atendido";
      default:
        return "Error";
    }
  }

  protected getDepartmentLabel(department_name: any) {
    if (!department_name) {
      return "";
    }
    return department_name;
  }

  protected getSectionLabel(section_name: any) {
    if (!section_name) {
      return "";
    }
    return section_name;
  }

  protected getUnitLabel(unit_name: any) {
    if (!unit_name) {
      return "";
    }
    return unit_name;
  }

  protected getRoleLabel(code: number) {
    switch (code) {
      case 1:
        return "Administrador";
      case 2:
        return "Director";
      case 3:
        return "Jefe de Departamento";
      case 4:
        return "Ejecutivo";
      case 5:
        return "Ejecutivo Presencial";
      case 6:
        return "Call Center";
      case 8:
        return "Gabinete";
      case 9:
        return "Concejal";
      case 10:
        return "Gestión";
      default:
        return "Tipo no encontrado";
    }
  }

  protected getTypeLabel(code: any) {
    switch (code) {
      case 0:
        return "Web";
      case 1:
        return "App";
      case 2:
        return "Todos";
      case 3:
        return "Reserva";
      case 4:
        return "Otros/Varios";
    }
    return "Sin Información";
  }

  protected getAssignmentTypeLabel(code: number) {
    switch (code) {
      case 0:
        return "Automático";
      case 1:
        return "Director";
      case 2:
        return "Jefe de departamento";
      default:
        return "Error";
    }
  }

  protected getUnitTypeLabel(code: number) {
    switch (code) {
      case 0:
        return "Interno";
      case 1:
        return "Externo";
      default:
        return "Error";
    }
  }

  protected getTypeAssign(code: number) {
    switch (code) {
      case 0:
        return "Automático";
      case 1:
        return "Jefe de departamento";
      case 2:
        return "Gestión";
      default:
        return "Error";
    }
  }

  protected getGenre(code: number) {
    switch (code) {
      case 1:
        return this.$idComuna == -1 ? "Femenino" : "Mujer";
      case 2:
        return this.$idComuna == -1 ? "Masculino" : "Hombre";
      case 3:
        return "Otro";
      case 4:
        return "Prefiero no decirlo";
      case 5:
        return "Sin información";
      default:
        return "Error";
    }
  }

  protected getPostulationStateColor(postulation: any) {
    let color = "";
    return "#" + postulation.postulacion_estado.color;
  }
}
